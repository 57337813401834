%sans-light {
	font-family: $sans;
	font-style: normal;
	font-weight: 300;
}

%sans-regular {
	font-family: $sans;
	font-style: normal;
	font-weight: 400;
}
