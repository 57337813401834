.nav {
   margin-bottom: 1rem;

   @include min-up(37.5em) {
      margin-bottom: 2.5rem;
   }
}

.nav__list {
   @extend %list-reset;
   display: flex;
   flex-direction: column;
   
   @include min-up(37.5em) {
      flex-direction: row;
      justify-content: space-around;
   }

   li {
      margin-bottom: 1rem;
   }

   a {
      font-size: 1.25rem;
      text-decoration: none;
      
      @include min-up(37.5em) {
         padding: 1rem;
      }
   }
}