.footer-wrap {
   padding: 1rem;
   background-color: #333;
}

.footer {
   display: flex;
   justify-content: space-between;
   margin: 0 auto;
   max-width: $site-canvas;
   color: #fff;
}

.footer__tagline {
   display: none;
   margin: 0;

   @include min-up(37.5em) {
      display: block;
   }
}

.footer__social {
   @extend %list-reset;
   display: flex;
   flex-direction: column;
   width: 100%;

   @include min-up(24em) {
      flex-direction: row;
      width: auto;
   }
   
   li {
      margin: 0.5rem 0;
      text-align: center;

      @include min-up(24em) {
         text-align: left;
         margin: 0 1rem 0 0;
      }
   }
}