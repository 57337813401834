*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    overflow-x: hidden;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    @extend %sans-regular;
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    color: $color-black;
    background-color: $color-body-bg;
    overflow-x: hidden;
}

a, button {
    color: inherit;
}

img, svg {
    max-width: 100%;
    height: auto;
}

/* -------------------------------------------
	Site Wrapper
------------------------------------------- */

.site-canvas {
    margin: 0 auto;
    padding: 1.5rem 1.5rem 1rem;
    max-width: $site-canvas;

    @include min-up(30em) {
        padding: 3rem $site-padding 1rem;
    }
}
