/* -------------------------------------------
	Hidden Visually - Annouced to ATs
------------------------------------------- */

%visually-hidden {
    position: absolute !important;
    margin: -1px !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}

/* -------------------------------------------
	Media Queries
------------------------------------------- */

@mixin min-up($min) {
	@media only screen and (min-width: $min) { @content; }
}

@mixin max-down($max) {
	@media only screen and (max-width: $max * .99999) { @content; }
}

@mixin min-max($min, $max) {
	@media only screen and (min-width: $min) and (max-width: $max * .99999) { @content; }
}

/* -------------------------------------------
	UL/OL reset
------------------------------------------- */

%list-reset {
	margin: 0;
	padding: 0;
	list-style-type: none;
}


/* -------------------------------------------
	Fieldset & Legend reset
------------------------------------------- */

%inline-dl {

	dd, dt {
	  display: inline;
	  margin: 0;
	}

	dd:after{
		display: block;
		content: '';
	}
}


/* -------------------------------------------
	Fieldset & Legend reset
------------------------------------------- */

%fieldset-legend-reset {

    fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }

    legend {
        @extend %visually-hidden;
    }
}


/* -------------------------------------------
	Show/Hide text - Image Replacement
------------------------------------------- */

%hide-text {
	display: block; 	// always needed
	text-indent: 150%;
	white-space: nowrap;
	overflow: hidden;
}

%unhide-text {
	// set display as needed
	text-indent: 0;
	white-space: normal;
	overflow: visible;
}

/* -------------------------------------------
	Placeholder Text
------------------------------------------- */

@mixin placeholder {
  &.placeholder 				{ @content; }
  &:-moz-placeholder 			{ @content; }
  &::-moz-placeholder 			{ @content; }
  &::-webkit-input-placeholder 	{ @content; }
}

/*
Usage Example:

@include placeholder {
    font-style:italic;
    color: white;
    font-weight:100;
}
*/


/* -------------------------------------------
	Clearfix
------------------------------------------- */

@mixin clearfix() {
	&::after {
		content: "";
		clear: both;
		display: table;

		// Safari Flex Bug
		flex-basis: 0;
		order: 1;
	}
}

/* -------------------------------------------
	Vertical Centering
------------------------------------------- */

@mixin vertical-align($val: 50%) {

	// unset
	@if $val == 'none' {
	    top: auto;
		-webkit-transform: none;
		   -moz-transform: none;
		    -ms-transform: none;
				transform: none;

   	} @else {
		position: relative;
		top: $val;
		-webkit-transform: translateY(-50%);
		   -moz-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
				transform: translateY(-50%);
   	}
}
