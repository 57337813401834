/* -------------------------------------------
	Phase Colors
------------------------------------------- */

$color-body-bg: #F7F7F7;
$color-black:	 #222222;

$color-skill-uxstrategy  	: #296491;
$color-skill-accessibility	: #417C69;
$color-skill-development	: #DA1735;
$color-skill-teaching		: #B94B01;


/* -------------------------------------------
	Widths/Margins/Padding
------------------------------------------- */

$site-padding: 2em;
$site-canvas:  70em;


/* -------------------------------------------
	Font Stack
------------------------------------------- */

$sans: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;