.header {
   margin: 0 auto 2rem;

   @include min-up(37.5em) {
      margin: 0 auto 5rem;
   }
}

.header__title {
   @extend %hide-text;
   margin: 0 auto 1.5rem;
   width: 200px;
   height: 200px;
   border-radius: 50%;
   background-image: url('/assets/img/jsa.jpg');
   background-size: cover;

   @include min-up(37.5em) {
      margin: 0 auto 2.5rem;
      width: 300px;
      height: 300px;
   }
}

.header__text {
   @extend %sans-light;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   
   @include min-up(37.5em) {
      flex-direction: row;
      max-width: 90%;
   }

   .salutation {
      font-size: 2.5rem;
      
      @include min-up(37.5em) {
         margin-right: 2rem;
         font-size: 5rem;
      }
   }

   .body {
      font-size: 1.33rem;
      line-height: 1.75;

      .bold {
         @extend %sans-regular;
      }
   }
}