.skill-block {
   margin: 0 0 2.5rem;
   width: 100%;

   @include min-up(37.5em) {
      margin: 0 0 5rem;
      padding-top: 2rem;
   }
}

.skill__title {
   @extend %sans-light;
   margin: 0 0 1rem;
   font-size: 2.5em;

   @include min-up(37.5em) {
      margin: 0 0 2rem;
      font-size: 5em;
   }

   .skill-block--ux &            { color: $color-skill-uxstrategy; }
   .skill-block--accessibility & { color: $color-skill-accessibility; }
   .skill-block--development &   { color: $color-skill-development; }
   .skill-block--teaching &      { color: $color-skill-teaching; }
}

.skill__image {
   display: block;
   margin-bottom: 1rem;

   @include min-up(37.5em) {
      margin-bottom: 1rem;
      mask: url('../img/mask.svg');
      -webkit-mask-size: 100% 100%;
      -webkit-mask-repeat: no-repeat;
   }
}

.skill__intro {
   @extend %sans-light;
   position: relative;
   margin: 0 0 1rem;
   font-size: 1rem;
   line-height: 1.75;

   @include min-up(37.5em) {
      margin: 0 0 2.5rem;
      padding: 0 3rem 0 1.5rem;
      max-width: 75ch;
      font-size: 1.33rem;
      line-height: 1.75;
      transform: translateX(1.45rem);

      &::before {
         content: '';
         position: absolute;
         left: 0;
         width: 3px;
         height: 85%;
         top: 8%;
         border-left: 3px solid #ccc;

         .skill-block--ux &            { border-color: $color-skill-uxstrategy; }
         .skill-block--accessibility & { border-color: $color-skill-accessibility; }
         .skill-block--development &   { border-color: $color-skill-development; }
         .skill-block--teaching &      { border-color: $color-skill-teaching; }
      }
   }
}

.skill__list {
   margin: 0 1rem;
   padding: 0;
   line-height: 1.5;

   @include min-up(37.5em) {
      margin: 0 3.9rem;
   }

   li:not(:last-of-type) {
      margin-bottom: 0.75rem;
   }
}